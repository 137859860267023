import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import classnames from "classnames";
import TableSearch from "../../Table/tableSearch";
import Checkbox from "../../Checkbox";
import { useHighlight } from "../../../hooks/useHighlight";
import { toggleSelectItem } from "../../../helpers/toggleSelectItem";
import { useService } from "../../../hooks/useService";
import classes from "./ProgramsDropdown.module.scss";
import WellnessService from "../../../../services/WellnessService";
import ProgramsService from "../../../../services/ProgramsService";
import {
  PROGRAM_TYPES, PROGRAM_TYPES_LABELS,
  WELLNESS_TYPE
} from "../../../../groups/app/HealthProgram/constants";
import Swiper from "../../Swiper/Swiper";

const MIN_SEARCH_LENGTH = 3;
const DEFAULT_LIMIT = 100;

const ProgramsMultiSelectDropdown = ({ onChange, value = [], placeholder, error, label }) => {
  /**
   * @type {WellnessService}
   */
  const wellnessService = useService(WellnessService);
  /**
   * @type {ProgramsService}
   */
  const programsService = useService(ProgramsService);

  const [isOpen, updateIsOpen] = useState(false);
  const [activeTabs, setActiveTabs] = useState(PROGRAM_TYPES.NUTRITION);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const lastElementRef = useRef(null);

  const programApiFunction = {
    [PROGRAM_TYPES.NUTRITION]: (params) => {
      return programsService.getPrograms(params);
    },
    [PROGRAM_TYPES.FITNESS]: (params) => {
      return wellnessService.getPrograms({ wellnessType: WELLNESS_TYPE.FITNESS, ...params });
    },
    [PROGRAM_TYPES.WELLNESS]: (params) => {
      return wellnessService.getPrograms({ wellnessType: WELLNESS_TYPE.WELLNESS, ...params });
    }
  };

  const programTabs = Object.values(PROGRAM_TYPES).map((value) => ({
    value,
    id: value,
    label: PROGRAM_TYPES_LABELS[value]
  }));

  const searchRequest = useMemo(() => {
      return search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? search : '';
    }, [search]
  );

  const { decorateText } = useHighlight(searchRequest);

  const valueIds = useMemo(() => value.map(item => item.id), [value]);

  const loadData = useCallback(() => {
    setIsLoading(true);
    setPage(1);
    setHasNextPage(false);

    const params = { limit: DEFAULT_LIMIT, offset: 0, query: searchRequest };

    programApiFunction[activeTabs](params)
      .then(({ data, pagination }) => {
        setHasNextPage(pagination?.nextOffset < pagination?.totalCount);
        setPrograms(data);
      })
      .finally(() => setIsLoading(false));
  }, [searchRequest, activeTabs]);

  const loadMoreData = () => {
    if (isLoading || !hasNextPage) return;

    const nextOffset = (page) * DEFAULT_LIMIT;

    const params = { limit: DEFAULT_LIMIT, offset: nextOffset, query: searchRequest };

    setIsLoading(true);
    programApiFunction[activeTabs](params)
      .then(({ data }) => {
        setPrograms((prevState) => [...prevState, ...data]);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!lastElementRef.current) return;

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMoreData();
      }
    });

    observer.observe(lastElementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadMoreData, lastElementRef.current]);

  const checkIsEqualValue = (item1, item2) => {
    return item1.id === item2.id && item1.type === item2.type;
  };

  const toggleSelectItem = (item) => {
    value.find((valueItem) => checkIsEqualValue(valueItem, item))
      ? onChange(value.filter((valueItem) => !checkIsEqualValue(valueItem, item)))
      : onChange([...value, item]);
  };

  const showPlaceholder = !value.length;

  return (
    <>
      {!!label && <label>{label}</label>}

      <section className="d-flex align-items-center w-100">

        <Dropdown
          isOpen={isOpen}
          toggle={() => updateIsOpen(prevState => !prevState)}
          className="d-inline-block filter-dropdown cursor-pointer result-filter min-w-1-5 w-100"
          direction="down"
        >
          <DropdownToggle
            className={classnames('filter-toggle w-100', { 'with-border': isOpen, 'is-invalid': !!error })}
            tag="section"
          >
                    <span className={classnames('ms-2 me-1 pointer-events-none user-select-none text-truncate',
                      { 'text-secondary': showPlaceholder })}>
                        {
                          showPlaceholder
                            ? placeholder
                            : value.map(item => item.title).join(', ')
                        }
                    </span>
            <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none',
              { 'mdi-rotate-180': isOpen })}/>
          </DropdownToggle>
          <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
            <TableSearch onSearch={setSearch} placeholder="Search" search={search} className="my-2"/>

            <Swiper
              containerClassName="my-2"
              slidesPerView={programTabs?.length}
              spaceBetween={0}
              slides={programTabs.map((item) => {
                return <button
                  type="button"
                  key={item.id}
                  onClick={() => {
                    setPrograms([]);
                    setActiveTabs(item.id);
                  }}
                  className={classnames(
                    "text-nowrap",
                    classes.Slide,
                    item.id === activeTabs ? classes.Active : ""
                  )}
                >
                  {item.label}
                </button>;
              })}
            />

            <section>
              <ListGroup>
                <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                  {programs?.length
                    ? programs.map((item, index) => {
                        const itemTitle = item.name || item.fullName;

                        return (
                          <ListGroupItem className="bg-transparent border-0" key={item.id}>
                            <Checkbox
                              name="exercise"
                              text={<div {...decorateText(itemTitle)} />}
                              value={!!(value.find((valueItem) => {
                                return checkIsEqualValue(valueItem, { type: activeTabs, id: item.id });
                              }))}
                              onChange={
                                () => toggleSelectItem({ type: activeTabs, id: item.id, title: itemTitle })
                              }
                            />
                            {
                              index === programs.length - 1
                                ? <div
                                  ref={lastElementRef}
                                  className="d-flex justify-content-center">
                                </div>
                                : null
                            }
                          </ListGroupItem>
                        );
                      }
                    )
                    : <div className="text-secondary py-3 w-100 text-center">{!isLoading && "No result"}</div>
                  }
                  {
                    isLoading && <div className="d-flex my-2 justify-content-center">
                      <Spinner size="sm" color="primary"/>
                    </div>
                  }
                </div>
              </ListGroup>
            </section>
          </DropdownMenu>
        </Dropdown>
      </section>
      {error &&
        <span className="invalid-feedback d-block">{error}</span>
      }
    </>
  );
};

export default ProgramsMultiSelectDropdown;
