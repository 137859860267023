import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { SortableItem } from "./SortableItem";

export const SortableList = SortableContainer(
  ({
    items,
    afterOnChange,
    containerClassName,
    onChange,
  }) => {
    return (
      <div
        className={joinClassNames(
          `mobile-services-container`,
          containerClassName,
        )}
      >
        {items.map((item, index) => {
          return (
            <SortableItem
              key={`item-${item.id}`}
              index={index}
              listIndex={index.order}
              sortIndex={item.order}
              item={item}
              afterOnChange={afterOnChange}
              onChange={() => onChange(item.id)}
            />
          );
        })}
      </div>
    );
  }
);