import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLoading } from "../../../../base/hooks/useLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { useService } from "../../../../base/hooks/useService";
import UsersService from "../../../../services/UsersService";
import {
  useFilterProvider,
  useLocationSource,
  usePaginationProvider,
  useSearchProvider, useSortProvider,
  useDateRangeProvider,
  parseSorting
} from "../../../../base/components/Table/hooks";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { DATA_ACCESS_STATUSES, DEFAULT_DEBOUNCE_DELAY, USER_ROLES } from "../../../../base/constants/shared";
import { parseQuery } from "../../../../base/hooks/useQueryString";
import Table from "../../../../base/components/Table";
import { columns, SearchPlaceholder, TableHeader } from "./components";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import { LIMIT_OPTIONS } from "../../../../base/constants/pagination";
import StorageService from "../../../../services/StorageService";
import { KEY_USER } from "../../../../base/constants/storage";
import ToastService from "../../../../services/ToastService";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import { CUSTOMER_RESULTS_LINKS } from "../../CustomerResults/config";

const breadcrumbs = {
  title: "Customer management", breadcrumbItems: []
}

export default function UsersList() {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {ToastService}
   */
  const toastService = useService(ToastService);

  const currentAdminUser = storage.get(KEY_USER, { firstName: "", lastName: "" });

  const [users, updateUsers] = useState([]);
  const [usersPagination, updateUsersPagination] = useState({ totalCount: 0 });
  const [isViewProfilePopupOpenId, updateIsViewProfilePopupOpenId] = useState(false);
  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();
  const {
    limit = 15,
    offset = 0,
    firstName,
    id,
    createdAt,
    joinDate,
    dateOfBirth,
    lastActiveDate,
    search,
    dateOfBirthStartDate,
    dateOfBirthEndDate,
    lastActiveStartDate,
    lastActiveEndDate,
    joinDateStartDate,
    joinDateEndDate
  } = parseQuery(locationSearch);
  const [isLoading, { registerPromise }] = useLoading(true);

  const locationSource = useLocationSource();

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });

  const limitProvider = usePaginationProvider({
    source: locationSource,
    alias: "limit",
    scope: "",
    fallback: 15,
    onApplyClearScope: ["offset"]
  });

  const generateFilterProvidersParams = (names = []) => {
    return {
      source: locationSource,
      alias: names,
      scope: "",
      onApplyClearScope: ["offset"],
    }
  }

  const dateRangeProviders = {
    joinDate: useDateRangeProvider(generateFilterProvidersParams(["joinDateStartDate", "joinDateEndDate"])),
    lastActive: useDateRangeProvider(generateFilterProvidersParams(["lastActiveStartDate", "lastActiveEndDate"])),
    dateOfBirth: useDateRangeProvider(generateFilterProvidersParams(["dateOfBirthStartDate", "dateOfBirthEndDate"])),
  }

  const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

  const generateSortProviderParams = (name) => {
    const allParams = ["firstName", "id", "createdAt", "email",'joinDate', 'dateOfBirth', 'lastActiveDate'];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter(paramName => paramName !== name)
    }
  }

  const nameSortProvider = useSortProvider(generateSortProviderParams("firstName"));
  const emailSortProvider = useSortProvider(generateSortProviderParams("email"));
  const idSortProvider = useSortProvider(generateSortProviderParams("id"));
  const createdAtSortProvider = useSortProvider(generateSortProviderParams("createdAt"));
  const birthDateSortProvider = useSortProvider(generateSortProviderParams("dateOfBirth"));
  const lastActiveDateSortProvider = useSortProvider(generateSortProviderParams("lastActiveDate"));  

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: [""]
  })

  const filterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'period',
    onApplyClearScope: ["offset"]
  })

  const resendRequest = (id) => {
    toastService.success("The data view request has been successfully sent");
    usersService.resetDataViewAccessRequest(id);
  }

  const getUsers = useCallback(() => {
    const sortObject = parseSorting({
      firstName,
      id,
      createdAt,
      joinDate,
      dateOfBirth,
      lastActiveDate,
    });
    const p = {
      limit,
      offset,
      q: debouncedSearch,
      dateOfBirthStartDate,
      dateOfBirthEndDate,
      lastActiveStartDate,
      lastActiveEndDate,
      joinDateStartDate,
      joinDateEndDate,
      ...sortObject
    };
    registerPromise(
      usersService.getUsers(p).then(({ data, pagination }) => {
        updateUsers(data);
        updateUsersPagination(pagination);
      })
    );
  }, [
    offset,
    limit,
    dateOfBirthStartDate,
    dateOfBirthEndDate,
    lastActiveStartDate,
    lastActiveEndDate,
    joinDateStartDate,
    joinDateEndDate,
    debouncedSearch,
    firstName,
    id,
    createdAt,
    joinDate,
    dateOfBirth,
    lastActiveDate,
  ]);

  useEffect(() => {
    getUsers();
  }, [getUsers])

  const sortProviders = useMemo(
    () => ({
      firstName: nameSortProvider,
      email: emailSortProvider,
      id: idSortProvider,
      createdAt: createdAtSortProvider,
      dateOfBirth: birthDateSortProvider,
      lastActiveDate: lastActiveDateSortProvider
    }),
    [nameSortProvider, emailSortProvider, idSortProvider, createdAtSortProvider, birthDateSortProvider, lastActiveDateSortProvider]
  );

  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
      <Table
        columns={columns(currentAdminUser)}
        data={users}
        isAddNewBiomarker
        loading={isLoading}
        HeaderComponent={TableHeader}
        isLimitEditable={true}
        limit={limitProvider.getValue()}
        limitProvider={limitProvider}
        totalCount={usersPagination.totalCount}
        offset={offset}
        sortProviders={sortProviders}
        paginationProvider={paginationProvider}
        dateRangeProviders={dateRangeProviders}
        onDelete={() => {
        }}
        onEdit={() => {
        }}
        actions={{
          resendRequest: resendRequest
        }}
        isRowClick
        rowClickHandler={(row) => {
          // -- bypass data access for non practitioner admin users --
          if (currentAdminUser.role !== USER_ROLES.practitioner) {
            return navigate({
              pathname: CUSTOMER_RESULTS_LINKS.DETAILS.replace(':id', row.id),
            });
          }

          const { id, dataAccessStatus } = row;
          if (dataAccessStatus !== DATA_ACCESS_STATUSES.approved) {
            return updateIsViewProfilePopupOpenId(id);
          }
          navigate({
            pathname: CUSTOMER_RESULTS_LINKS.DETAILS.replace(':id', id),
          })
        }}
        searchProvider={searchProvider}
        commonPlaceholder="No users found"
        placeholderForSearch={<SearchPlaceholder/>}
        filterProvider={filterProvider}
        limitOptions={LIMIT_OPTIONS}
      />
      <ConfirmPopup
        isOpen={isViewProfilePopupOpenId}
        updateIsOpen={updateIsViewProfilePopupOpenId}
        onSubmit={() => {
          resendRequest(isViewProfilePopupOpenId);
        }}
        title="View Profile"
        description="Customer profile is not available to view until your Data request is pending."
        submitBtnText="Resend request"
        className="w-100"
      />
    </BaseLayoutWithCard>
  )
}