import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import TableSearch from '../../../../base/components/Table/tableSearch';
import { CUSTOMER_MANAGEMENT_LINKS } from '../config';
import { useLocationQuery } from '../../../../base/hooks/useQueryString';
import { useHighlight } from '../../../../base/hooks/useHighlight';
import Icon from '../../../../base/components/Icon';
import { replaceUrlParams } from '../../../../base/helpers/url';
import { DateRangePicker } from '../../../../base/components/DateRangePicker';
import { formatISODate, transformJsDateToIso } from '../../../../base/helpers/date';
import Avatar from '../../../../base/components/Avatar';
import Button from '../../../../base/components/Button';
import { BUTTON_COLORS } from '../../../../base/components/Button/appearance';
import UploadFileModal from './UploadFileModal';
import {
  CSV_FILE_MASK, DATA_ACCESS_STATUSES,
  DATA_ACCESS_STATUSES_COLORS,
  DATA_ACCESS_STATUSES_LABELS,
  USER_ROLES
} from '../../../../base/constants/shared';
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { KEY_USER } from "../../../../base/constants/storage";
import StorageService from "../../../../services/StorageService";
import { useService } from "../../../../base/hooks/useService";

export const SearchPlaceholder = () => <>No customer found.</>;

export const TableFilter = ({ filterProvider }) => {
  const [isOpen, updateIsOpen] = useState(false);
  const currentValue = filterProvider.getValue();
  return (
    <section className="d-flex align-items-center">
      <label className="mb-0">Filter by join date:</label>
    </section>
  );
};

export const UploadManuallyButton = ({ id }) => {
  const navigate = useNavigate();

  return <section
    className="d-flex align-items-center text-primary"
    onClick={() => navigate(replaceUrlParams(CUSTOMER_MANAGEMENT_LINKS.UPLOAD_MANUALLY, { id }))}
  >
    <Icon icon="uploadResults" className="me-2 upload-results-icon"/> Upload manually
  </section>;
}

export const actionsByDataAccessStatus = (id, actions) => ({
  [DATA_ACCESS_STATUSES.notSent]: (
    <section
      className="d-flex align-items-center text-primary"
      onClick={() => actions.resendRequest(id)}
    >
      <Icon icon="resend" className="me-2 upload-results-icon"/> Send Request
    </section>
  ),
  [DATA_ACCESS_STATUSES.approved]: (
    <UploadManuallyButton id={id}/>
  ),
  default: (
    <section
      className="d-flex align-items-center text-primary"
      onClick={() => actions.resendRequest(id)}
    >
      <Icon icon="resend" className="me-2 upload-results-icon"/> Resend Request
    </section>
  ),
});

export const TableHeader = ({ searchProvider, filterProvider, sortProviders, dateRangeProviders = {} }) => {
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  const currentAdminUser = storage.get(KEY_USER, { firstName: "", lastName: "" });

  const [isUploadDNAFileModalOpen, setIsUploadDNAFileModalOpen] = useState(false);

  const [[dateOfBirthStartDate, dateOfBirthEndDate], updateBirthDate] = useState(dateRangeProviders.dateOfBirth.getValue());
  const [[joinDateStartDate, joinDateEndDate], updateJoinDate] = useState(dateRangeProviders.joinDate.getValue());
  const [[lastActiveStartDate, lastActiveEndDate], updateLastActive] = useState(dateRangeProviders.lastActive.getValue());

  const now = new Date();
  const before = new Date();
  before.setFullYear(2022);
  const yearDiffFromNow = Math.abs(before.getFullYear() - now.getFullYear());

  return (
    <section className="mb-4 d-flex flex-column">
      <section className="d-flex w-100 mb-3 align-items-center justify-content-between">
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search customer name, email, order ID or sample ID"
        />
        {currentAdminUser.role !== USER_ROLES.practitioner ? (
          <Button type="button" color={BUTTON_COLORS.primary}
                  onClick={() => setIsUploadDNAFileModalOpen(prevState => !prevState)}>
            <Icon icon="uploadCloud" className="me-2"/>
            Upload DNA file
          </Button>
        ) : null}
      </section>
      <section className="d-flex">
        <DateRangePicker
          label="Join date"
          className="me-4 w-20"
          startDate={joinDateStartDate}
          yearCount={yearDiffFromNow}
          endDate={joinDateEndDate}
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              updateJoinDate([null, null]);
              dateRangeProviders.joinDate.setValue({});
              return;
            }
            const startDateFormatted = DateTime.fromJSDate(startDate).startOf('day');
            updateJoinDate([startDateFormatted.toJSDate(), endDate]);

            if (endDate) {
              const values = {
                joinDateStartDate: transformJsDateToIso(startDate.toISOString(), true),
                joinDateEndDate: DateTime.fromJSDate(endDate).plus({ days: 1 }).toUTC().endOf('day').toISO(),
              };
              dateRangeProviders.joinDate.setValue(values);
            }
          }}
        />
        <DateRangePicker
          label="Birth date"
          className="me-4 w-20"
          startDate={dateOfBirthStartDate}
          yearCount={100}
          endDate={dateOfBirthEndDate}
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              updateBirthDate([null, null]);
              dateRangeProviders.dateOfBirth.setValue({});
              return;
            }
            const startDateFormatted = DateTime.fromJSDate(startDate).startOf('day');
            updateBirthDate([startDateFormatted.toJSDate(), endDate]);

            if (endDate) {
              const values = {
                dateOfBirthStartDate: transformJsDateToIso(startDate.toISOString(), true),
                dateOfBirthEndDate: DateTime.fromJSDate(endDate).plus({ days: 1 }).toUTC().endOf('day').toISO(),
              };
              dateRangeProviders.dateOfBirth.setValue(values);
            }
          }}
        />
        <DateRangePicker
          label="Last active date"
          className="me-4 w-20"
          startDate={lastActiveStartDate}
          yearCount={yearDiffFromNow}
          endDate={lastActiveEndDate}
          onChange={([startDate, endDate]) => {
            if (!startDate && !endDate) {
              updateLastActive([null, null]);
              dateRangeProviders.lastActive.setValue({});
              return;
            }
            const startDateFormatted = DateTime.fromJSDate(startDate).startOf('day');
            updateLastActive([startDateFormatted.toJSDate(), endDate]);

            if (endDate) {
              const values = {
                lastActiveStartDate: transformJsDateToIso(startDate.toISOString(), true),
                lastActiveEndDate: DateTime.fromJSDate(endDate).plus({ days: 1 }).toUTC().endOf('day').toISO(),
              };
              dateRangeProviders.lastActive.setValue(values);
            }
          }}
        />
      </section>

      {isUploadDNAFileModalOpen && (
        <UploadFileModal
          isOpen={isUploadDNAFileModalOpen}
          updateIsOpen={setIsUploadDNAFileModalOpen}
          title="Upload DNA file"
          description="Drag & drop your DNA file or click to upload."
          submitBtnText="Confirm"
          fileMask={CSV_FILE_MASK}
        />
      )}
    </section>
  );
};

export const columns = (currentAdminUser) => [
  {
    Header: 'Customer name',
    accessor: 'firstName',
    width: 230,
    canSort: true,
    Cell: ({
      row: {
        original: { firstName, lastName, avatar },
      },
    }) => {
      const { params } = useLocationQuery();
      const { decorateText } = useHighlight(params.search);
      return (
        <section className="d-flex align-items-center ">
          <Avatar avatar={avatar} firstName={firstName} className="table-item-avatar" />
          <div {...decorateText(firstName + ' ' + lastName)} className="ms-3 table-cell-25 text-truncate" />
        </section>
      );
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
    width: 220,
    canSort: true,
    Cell: ({ value }) => {
      const { params } = useLocationQuery();
      const { decorateText } = useHighlight(params.search);
      return <div className="table-cell-25 text-truncate" {...decorateText(value)} />;
    },
  },
  {
    Header: 'ID',
    accessor: 'id',
    width: 60,
    canSort: true,
    Cell: ({ value }) => {
      const { params } = useLocationQuery();
      const { decorateText } = useHighlight(params.search);
      return <div className="table-cell-25 text-truncate" {...decorateText(value)} />;
    },
  },
  {
    Header: 'Join date',
    accessor: 'createdAt',
    width: 110,
    canSort: true,
    Cell: ({ value }) => {
      return formatISODate(value);
    },
  },
  {
    Header: 'Birth date',
    accessor: 'dateOfBirth',
    width: 110,
    canSort: true,
    Cell: p => {
      const date = p.data[p.row.index]?.additionalField?.dateOfBirth;
      return date ? date.split('-').reverse().join('/') : '-';
    },
  },
  {
    Header: 'Last activ. date',
    accessor: 'lastActiveDate',
    width: 100,
    canSort: true,
    Cell: ({ row }) => {
      return formatISODate(row?.original?.additionalField?.lastActiveDate);
    },
  },
    ...(currentAdminUser.role === USER_ROLES.practitioner ? [{
    Header: () => <div className="">Status</div>,
    accessor: 'dataAccessStatus',
    width: 50,
    Cell: ({
             row: {
               original: { dataAccessStatus },
             },
           }) => {
      return (
          <section
            className="d-flex align-items-center"
          >
           <div className={joinClassNames('status-badge', DATA_ACCESS_STATUSES_COLORS[dataAccessStatus])}>
             {DATA_ACCESS_STATUSES_LABELS[dataAccessStatus]}</div>
          </section>
      );
    },
  }] : []),
  {
    Header: () => <div className="">Actions</div>,
    accessor: 'actions',
    width: 170,
    Cell: ({
             row: {
               original: { id, dataAccessStatus },
             },
             actions,
           }) => {
      return (
        currentAdminUser.role === USER_ROLES.practitioner ?
          <>
            {actionsByDataAccessStatus(id, actions)[dataAccessStatus] || actionsByDataAccessStatus(id, actions).default}
          </>
          :
          <UploadManuallyButton id={id} />
      );
    },
  },
];
