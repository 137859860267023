import React, { useEffect, useRef } from 'react';
import { Row, Spinner } from "reactstrap";
import Breadcrumb from "../../../base/components/Common/Breadcrumb2";
import { CUSTOMER_MANAGEMENT_LINKS } from "./config";
import { useNavigate, useParams } from "react-router-dom";
import useConfig from "../../../base/hooks/useConfig";


const breadcrumbs = {
  title: "Customer’s detailed page",
  breadcrumbItems: [
    { title: "Customer Management", link: CUSTOMER_MANAGEMENT_LINKS.LIST },
    { title: "View Customer Dashboard" }
  ]
}

export default function ViewCustomerDashboard() {
  const { config } = useConfig();
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const { sessionCode } = useParams();

  const eventListener = (message) => {
    console.log('message received', message);
    const SESSION_COMPLETED_MESSAGE = "session.completed";
    if(message.data.type === SESSION_COMPLETED_MESSAGE){
      window.location = CUSTOMER_MANAGEMENT_LINKS.LIST;
    }
  };

  useEffect(() => {
    window.onbeforeunload = function() {
      return "";
    };
    window.addEventListener('message', eventListener);
    console.log('eventListener added');
    return () => {
      window.onbeforeunload = null;
      window.removeEventListener('message', eventListener);
    }
  }, []);

  return (
    <>
      <Breadcrumb {...breadcrumbs} />
      <Row>
        <div className="position-absolute w-100">
          <div className="iframe-spinner d-flex justify-content-center align-items-center">
            <Spinner size="sm" color="primary"/>
          </div>
        </div>
        <iframe src={`${config.FRONTEND_BASE_URL}/sign-in?sessionCode=${sessionCode}`}
                name={"customer-dashboard-preview"}
                width="100%"
                style={{
                  height: "calc(100vh - 208px)",
                  backgroundColor: "white",
                }}
                ref={iframeRef}/>
      </Row>
    </>
  )
}
