import Http from "./Http";

class AuthService extends Http {
  static $displayName = "AuthService";

  async login({ email, password }) {
    return await this.post("/admins/sessions", { email, password }, { customToast: true })
  }

  async logout() {
    return await this.delete("/admins/sessions");
  }

  async generateSessionCode(id) {
    return await this.post("/admins/sessions/code", { id });
  }

  async getSessionCode() {
    return await this.get("/sessions/codes");
  }

  async getSessionByCode(code) {
    return await this.post("/sessions/codes", { code });
  }

  async sendRestorePasswordEmail({ email }) {
    return await this.post("/admins/verifications/password", { email }, { customToast: true });
  }

  async resetPassword(values) {
    return await this.patch("/admins/verifications/password", values);
  }

  async checkLinkRelevance(params) {
    return await this.get("/admins/verifications/password", { params, customToast: true  });
  }

  async confirmMfaVerification(data){
    return await this.patch("/users/additional-authentications", data, { customToast: true })
  }

  async resendMfaCode(data){
    return await this.put("/users/additional-authentications", data)
  }
}

export default AuthService;
