import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

export const SortableItem = SortableElement(
  ({
    item,
    onChange
  }) => {
    const { id, name, isEnabled } = item;
    return (
      <div
        className="mobile-services-item"
      >
        <div className="d-flex align-items-center">{name}</div>
        <div className="d-flex align-items-center">
          <input type="checkbox" name={id} checked={isEnabled} onChange={onChange} />
        </div>
      </div>
    );
  }
);