import * as yup from "yup";
import { MIN_STRING_LENGTH, MAX_TITLE_LENGTH, ALLOWED_SYMBOLS_REGEX, MIN_ITEMS_QUANTITY } from "../constants";


export const initialValues = {
  organisationLogoMobile: "",
  primaryColorMobile: "",
  secondaryColorMobile: ""
};

export const validationSchema = yup.object().shape({
  primaryColorMobile: yup
    .string()
    .min(MIN_STRING_LENGTH)
    .max(MAX_TITLE_LENGTH)
    .required("Is required"),
  secondaryColorMobile: yup
    .string()
    .min(MIN_STRING_LENGTH)
    .max(MAX_TITLE_LENGTH)
    .required("Is required"),
});