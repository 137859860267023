import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from "reactstrap";
import { atom, useAtomValue, useSetAtom } from 'jotai';

import { FieldArray, useField } from "formik";

import Icon from "../../../../../../base/components/Icon";
import PlusButtonWithTooltip from "../../../../../../base/components/PlusButtonWithTooltip";
import { useService } from "../../../../../../base/hooks/useService";
import ContentsService from "../../../../../../services/ContentsService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import _ from "lodash";
import { BUTTON_COLORS } from "../../../../../../base/components/Button/appearance";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";
import Button from "../../../../../../base/components/Button";
import ContentByTypeDropdown
  from "../../../../../../base/components/Dropdowns/ContentByTypeDropdown/ContentByTypeDropdown";

const DEFAULT_FIELD_NAME = "wellnessActionPlans";
const ACCORDION_ITEM_ID = "1";
const MAX_RELATED_CONTENT = 10;

const preloadedContentAtom = atom([{}]);

function ActionItems({ value, setValue, index: levelIndex, removeLevel }) {
  const preloadedContent = useAtomValue(preloadedContentAtom);

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
  });

  const onDragEnd = (result, replace) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = Array.from(value.actionPlans);

    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    for (const [index, item] of items.entries()) {
      item.step = index + 1;
      replace(index, item);
    }
  };

  const handleContentChange = (replace, index, item, value) => {
    item.contentId = value?.id;
    item.name = value?.name || value?.title;
    replace(index, item);
  }

  const actionPlans = _.chain(value)
    .get('actionPlans', [])
    .sortBy('step')
    .value();

  return (
    <FieldArray name={`${DEFAULT_FIELD_NAME}.${levelIndex}.actionPlans`}>
      {({ push, replace, remove }) => (
        <DragDropContext onDragEnd={(result) => onDragEnd(result, replace)}>
          <Droppable droppableId={`relatedContentDND-${levelIndex}`}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {actionPlans.map((item, index) =>
                  <Draggable
                    key={`${item?.id}-${index}`}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={false}>

                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className="mb-3"
                      >
                        <div className="d-flex align-items-center gap-4 justify-content-between">
                          <div className="d-flex flex-grow-1 align-items-center gap-3">
                            <div className="d-flex">Step {item?.step}</div>

                            <div className="flex-grow-1">
                              <ContentByTypeDropdown
                                onChange={(value) => handleContentChange(replace, index, item, value)}
                                value={item}
                                placeholder="Select content (optional)"
                                content={preloadedContent}
                              />
                            </div>
                          </div>

                          <div className="d-flex gap-3">
                            <div
                              {...provided.dragHandleProps}
                            >
                              <Icon
                                icon="dndIcon"
                                width={24}
                                height={24}
                                className="cursor-pointer impacts-section__view"
                              />
                            </div>

                            <div
                              onClick={() => {
                                remove(index)
                                if(value?.actionPlans.length === 1) {
                                  removeLevel(levelIndex)
                                }
                              }}
                            >
                              <Icon
                                icon="trashIcon"
                                width={24}
                                height={24}
                                className="cursor-pointer impacts-section__view "
                              />
                            </div>
                          </div>
                        </div>
                      </div>)}
                  </Draggable>)
                }

                {provided.placeholder}

                <PlusButtonWithTooltip
                  id={"addRelatedContend"}
                  disabled={value?.actionPlans?.length >= MAX_RELATED_CONTENT}
                  onClick={() => push({ step: value?.actionPlans?.length + 1 })}
                  buttonText="Add step"
                  tooltipText={`Max. ${MAX_RELATED_CONTENT} options can be added.`}
                  containerClassName="mt-3"
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </FieldArray>

  )
}

function Level({ value, setValue, index, removeLevel }) {
  const [open, setOpen] = useState([ACCORDION_ITEM_ID]);

  const toggle = () => {
    setOpen(open.length ? [] : [ACCORDION_ITEM_ID]);
  };

  return (
    <Accordion flush open={open} toggle={toggle}>
      <AccordionItem
        id={ACCORDION_ITEM_ID}
      >
        <AccordionHeader
          className="white-bg-accordion-header border-0"
          targetId={ACCORDION_ITEM_ID}>
          <span className="accordion-description font-weight-semibold">Level {value?.level}</span>
        </AccordionHeader>

        <AccordionBody accordionId={ACCORDION_ITEM_ID}>
          <ActionItems value={value} setValue={setValue} index={index} removeLevel={removeLevel}/>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  )
}

export function PlanOfAction() {
  /**
   * @type {ContentsService}
   */
  const contentService = useService(ContentsService);
  const setContents = useSetAtom(preloadedContentAtom);
  const [isLoading, { registerPromise }] = useLoading(true);
  const [{ value }, { error }, { setValue }] = useField(DEFAULT_FIELD_NAME);

  const loadContents = (query = null) => {
    const DEFAULT_LIMIT = 100;
    const params = { limit: DEFAULT_LIMIT, offset: 0, query: query };

    registerPromise(contentService.getContents(params))
      .then((result => {
        setContents(result);
      }));
  };

  useEffect(() => {
    loadContents();
  }, []);

  return <>
    <FieldArray name={`${DEFAULT_FIELD_NAME}`} key={`levels`}>
      {({ push, remove }) => (
        <>
          <div className="bg-body">
            {
              value.map((item, index) => (
                <Level key={index} value={item} index={index} setValue={setValue} removeLevel={remove}/>
              ))
            }
          </div>

          <div className="levels-buttons d-flex align-items-center mt-4">
            <PlusButtonWithTooltip
              id={"addLevel"}
              onClick={() => push({
                level: value.length + 1,
                actionPlans: [{
                  step: 1
                }]
              })}
              buttonText="Add level"
            />
            or
            <Button
              color={BUTTON_COLORS.transparent}
              className={joinClassNames("mb-0 text-primary no-border d-flex align-items-center")}
              onClick={() => {
                const latestLevel = _.clone(value[value.length - 1]);
                latestLevel.level = +latestLevel.level + 1;
                push(latestLevel);
              }}
            >
              <i className="bx bx-copy me-2"/>Copy level
            </Button>
          </div>
        </>
      )}
    </FieldArray>
    {
      error &&
      <span className="invalid-feedback d-block">{error}</span>
    }
  </>;
}