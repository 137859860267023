import PropTypes from 'prop-types'
import React, { useMemo, useState } from "react"
import { connect } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import StorageService from "../../../services/StorageService"
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu,
} from "../../../store/actions"
import { KEY_USER } from "../../constants/storage"
import joinClassNames from "../../helpers/joinClassNames"
import { useService } from "../../hooks/useService"
import NotificationsDropdown from "./NotificationsDropdown"
import Icon from "../Icon";
import * as _ from "lodash";
import ToasterService from "../../../services/ToastService";
import useConfig from "../../hooks/useConfig";
import { USER_ROLES } from "../../constants/shared";

const pagesWithIFrame = ["/app/customer-management/view-dashboard"];

const Header = () => {
  const [isOpen, updateIsOpen] = useState(false);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const location = useLocation();
  const { config } = useConfig();

  const isIFramePage = useMemo(() => {
    const path = _.take(location.pathname.split('/'), 4).join('/');
    return pagesWithIFrame.includes(path);
  }, [location]);

  const handleBack = () => {
    toastService.info("Closing session, please wait...");
    // event listener for callback message from iframe placed in ViewCustomerDashboard component
    window.frames["customer-dashboard-preview"].location = `${config.FRONTEND_BASE_URL}/logout`;
  }

  const { firstName = "", lastName = "", role } = storage.get(KEY_USER, { firstName: "", lastName: "" });

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box" ref={
              // hack for using !important in jsx to override background color from dynamic theme styles
              (el) => {
                if (el && (role === USER_ROLES.practitioner || isIFramePage)) {
                  el.style.setProperty('background-color', '#ffffff', 'important');
                }
              }}>
              <Link to="/" className="logo logo-light">
                <span className="logo-lg">
                </span>
              </Link>
            </div>
          </div>
          <section className="navbar-container">
            <section>
              {isIFramePage ? <button className="btn ms-3" onClick={handleBack}><Icon icon={"arrowLeft"}/> Back</button> : null}
            </section>
            <section>
            <NotificationsDropdown/>
              <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn header-item no-outline no-border ps-0"
                  id="page-header-user-dropdown"
                  tag="button"
                >
                  <section className="pointer-events-none">
                    <span className="ms-2 me-1">{firstName} {lastName}</span>
                    <i className={joinClassNames("mdi mdi-chevron-down", isOpen && "mdi-rotate-180")}/>
                  </section>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem className="px-3 d-flex align-content-center">
                    <i className="bx bx-user font-size-16 align-middle me-2"/>
                    Account settings
                  </DropdownItem>
                  <div className="dropdown-divider"/>
                  <Link to="/auth/logout" className="dropdown-item px-3 d-flex align-content-center">
                    <i className="bx bx-log-out font-size-16 align-middle me-2 text-danger"/>
                    Logout
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </section>
          </section>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(Header)
