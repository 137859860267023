import PropTypes from 'prop-types'
import React, { Component } from "react"
import { connect } from "react-redux"
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
} from "../../../store/actions"

import Footer from "./Footer"
import Header from "./Header"
import Sidebar from "./Sidebar"
import { KEY_USER } from "../../constants/storage";
import StorageService from "../../../services/StorageService";
import { useService } from "../../hooks/useService";
import { USER_ROLES } from "../../constants/shared";
import joinClassNames from "../../helpers/joinClassNames";
import * as _ from "lodash";

const PAGES_WITHOUT_SIDEBAR = [
  '/app/customer-management/view-dashboard'
]

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userData: {},
      isContentFullWidth: false,
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  }

  getCurrentPath = () => {
    return _.take(location.pathname.split('/'), 4).join('/');
  }

  componentDidMount() {
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);

    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    // Scroll Top to 0
    window.scrollTo(0, 0)

    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme)
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth)
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType)
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme)
    }

    const userData = storage.get(KEY_USER, {})

    const isContentFullWidth = userData.role === USER_ROLES.practitioner || PAGES_WITHOUT_SIDEBAR.includes(this.getCurrentPath());
    this.setState((prevState) => ({ ...prevState, userData, isContentFullWidth }));
  }

  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile)
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile)
    }
  };

  render() {
    return (
      <>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"/>
              <div className="chase-dot"/>
              <div className="chase-dot"/>
              <div className="chase-dot"/>
              <div className="chase-dot"/>
              <div className="chase-dot"/>
            </div>
          </div>
        </div>
        <div id="layout-wrapper" className={joinClassNames("admin-panel", this.state.isContentFullWidth ? "admin-panel-full-width" : "")}>
          <Header toggleMenuCallback={this.toggleMenuCallback}/>
          {!this.state.isContentFullWidth ?
            <Sidebar
              theme={this.props.leftSideBarTheme}
              type={this.props.leftSideBarType}
              isMobile={this.state.isMobile}
              schema={this.props.schema}
            /> : null}
          <div className="main-content">
            {this.props.children}
          </div>
          <Footer fullSize/>
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  schema: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
};

const mapStateToProps = state => {
  return {
    ...state.Layout,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(Layout)
