import React from 'react';
import { Container } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';

import { MOBILE_APP_CONFIGURATION_ROUTES } from './config';

import { MobileAppCustomisationForm } from "./Form/index";

export default function MobileAppCustomisation() {
  return (
    <Container fluid className="content">
      <Routes>
        <Route
          path={MOBILE_APP_CONFIGURATION_ROUTES.BASE}
          element={<MobileAppCustomisationForm/>}
        />
      </Routes>
    </Container>
  );
}